.aboutme-ctn {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    font-family: 'Poppins', sans-serif;
    background-color: rgb(237 237 237);

    /* min-height: 100%; */
}


@media only screen and (max-width: 920px) {
    .aboutme-ctn {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        font-family: 'Poppins', sans-serif;
    
        /* min-height: 100%; */
    }
}



.aboutme-ctn > .title {
    font-size: 3rem;
    color: #616161;
    margin-top: 3rem;
    /* font-family: 'Poppins', sans-serif; */
}

.aboutme-ctn > .title > span{
  
    /* color: #32326e; */
    color: #b2321e;
    
}

.aboutme-ctn > .description {
    /* background-color: #b2321e; */
    color: #fff;
    font-size: 1.2rem;
    /* padding: 2rem; */
    margin: 1em auto;
    width: 85%;
}

.description > .sentence {
    /* background-color: #b2321e; */
    color: #000;
    margin: 1rem;
    font-family: 'Roboto';
    /* padding: 1rem; */
    /* box-shadow: 2px 2px 6px #888880; */
    /* border-radius: 30px; */
}

.h1 {
    font-size: 2rem;
    font-weight: bold;
}

.skills {
    display: flex;
    flex-direction: column;
    width: 40vw;
    align-items: center;
}


@media only screen and (max-width: 920px) {
    
    .skills {
        width: 80vw;
    }
   
}

.skills > .heading{
    font-size: 1.8rem;
    color: #616161;
    margin-top: 3rem;
}

.skill-items {
    display: flex;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: space-around;
    flex-wrap: wrap;
}

.skill-icon {
    margin: 2rem;
    text-align: center;
    flex: 1;
}

.skill-icon > i {
    font-size: 3rem;
}

.skill-icon > .icon-name {
    color: #616161;
    /* letter-spacing: 2px; */
    /* text-transform: capitalize; */
}