.home-ctn {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    padding: 1rem;
    background-image: url("/src/assets/images/laptopbg.jpg");
    background-size: cover;
    min-height: 100%;
    background-color: #fff;
}

@media only screen and (max-width: 920px) {
    .home-ctn {
        background-position: -17rem -5rem;
    }

    .introduction {
        min-width: 100px;
    }

    }

    @media only screen and (min-width: 920px) {
        .introduction {
            min-width: 850px;
        }
    
        }

.introduction {
    padding: 1em;
    margin: auto 1em 4rem;
    
}

.introduction .title {
    font-size: 2rem;
    color: #b2321e;
    font-weight: bold;
    font-family: 'Cherry Cream Soda', cursive;
}

.introduction .subtitle {
    font-size: 1.5rem;
    color: #fff;
    font-weight: bold;
}

.home-buttons {
    display: flex;
    margin-top: 2em;
    justify-content: space-between;
    width: 25%;
}