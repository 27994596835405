.contact-ctn {
    background-color: rgb(6 27 59);
    padding: 2rem 2rem 5rem;
}

.contact-ctn > .heading {
    text-align: center;
    font-size: 3rem;
    color: #b2321e;
    font-family: 'Poppins', sans-serif;
    margin: 3rem 1rem 3rem;
}

.contact-ctn > .contact-info {
    text-align: center;
    margin: 3rem 1rem;
    font-size: 1.4rem;
    color: #fff;
}

.contact-info > span {
    color: #b2321e;
}


.contact-ctn > .social-media-links-ctn {
    display: flex;
    width: 50%;
    margin: 1rem auto;
    justify-content: space-around;
    margin-top: 2em;
}

.social-media-link {
    text-decoration: none;
    color: white;
}

.social-media-links-ctn > .social-media-icon {
    border: 3px solid transparent;
    padding: 10px 5px;
    transition: all 500ms ease; 
    color: white;
}

.social-media-links-ctn > .social-media-icon:hover {
    cursor: pointer;
    border-bottom: 3px solid #cb3e3e;
    -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}