.app-container {
    display: flex;
    height: 100vh;
    font-size: 1rem;
    overflow-y: hidden;
}

@media only screen and (max-width: 920px) {
  .app-container {
      flex-direction: column;
  }
  }

.app-content {
    /* width: 88vw; */
    display: flex;
    flex-direction: column;
    flex: 5;
    overflow-y: auto;

}