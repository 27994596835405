.nav-bar {
    background-color: var(--primary-color);
    color: #fff;
    display: none;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.nav-bar .active {
    display: flex;
}

.mobile-nav-bar {
    height: 4vh;
    z-index: 3;
    display: flex;
    padding: 1em;
    background-color: var(--primary-color);
}

.mobile-nav-bar > .nav-bar-icon {
   color: white;
   transition: all 0.3s linear;
}



@media only screen and (max-width: 920px) {

    .nav-bar {
        background-color: var(--primary-color);
        color: #fff;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100vw;

        position: fixed;
        opacity: 0;
        z-index: 1;
        visibility: hidden;
        height: 0%;
        transition: opacity 0.50s, visibility 0.25s, height 0.50s;
        overflow: hidden;
    }
   
    .nav-bar.active {
   
    
    opacity: 1;
    visibility: visible;
    height: 100vh;
   
    }
  }

  @media only screen and (min-width: 920px) {
    .nav-bar {
        display: flex;
        flex: 1;
        border: 0.5rem solid var(--dark-blue-color);
    }

    .mobile-nav-bar {
       display: none;
    }
  }

.nav-bar-list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0;
    font-size: 1.2em;
    height: 50%;
    font-weight: bold;
    letter-spacing: 2px;
   
}

.nav-bar-item {
    list-style: none;
    text-decoration: none;
    color: #fff;
    border-bottom: 5px solid transparent;
    transition: border-bottom 0.6s linear;

}

.nav-bar-item:hover {
    border-bottom: 5px solid var(--dark-blue-color);
    cursor: pointer;
}

.selected {
    border-bottom: 5px solid var(--dark-blue-color);
}

/* .active {
    border-bottom: 5px solid #2a2a52;
} */

.logo {
    font-size: 3rem;
    font-family: 'Cherry Cream Soda';
    font-weight: bold;
    border: 3px solid;
    color: rgb(6 27 59);
    transition: all 500ms ease; 
}

.logo:hover {
    cursor: pointer;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.social-media-links {
    display: flex;
    width: 80%;
    justify-content: space-around;
    margin-top: 2em;
}

.social-media-link {
    text-decoration: none;
    color: white;
}

.social-media-links > .social-media-icon {
    border: 3px solid transparent;
    padding: 10px 5px;
    transition: all 500ms ease; 
}

.social-media-icon:hover {
    cursor: pointer;
    border-bottom: 3px solid rgb(6 27 59);
    border-radius: 2em;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}