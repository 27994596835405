.education-ctn {
    background-color: rgb(44 60 96);
    padding: 2rem;
    padding-bottom: 10rem;

    background-color: #fff;
    /* min-height: 80vh; */

}

.education-ctn > .heading {
    text-align: center;
    font-size: 3rem;
    color: #b2321e;
    font-family: 'Poppins', sans-serif;
    margin: 3rem 1rem 3rem;
}

.education-ctn > .timeline-desktop {
    width: 66%;
    margin: 2rem auto;
}

.education-ctn > .timeline-mobile {
    margin: 1rem;
}