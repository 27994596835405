.experience-ctn {
    background-color: rgb(44 60 96);
    padding: 2rem;

    background-color: #fff;
}

.experience-ctn > .heading {
    text-align: center;
    font-size: 3em;
    color: #b2321e;
    font-family: 'Poppins', sans-serif;
    margin: 3rem 1rem 3rem;
}

.experience-ctn > .timeline-desktop {
    width: 66%;
    margin: 2rem auto;
}

.experience-ctn > .timeline-mobile {
    margin: 1rem;
}